import React from "react";
import { graphql, Link } from "gatsby";
import Image from "../components/Image";

import Layout from "../components/Layout";
import PortableText from "../components/PortableText";

export const query = graphql`
  {
    sanityFijiBaatPage {
      pageTitle
      coverImage {
        asset {
          id
          originalFilename
          url
        }
        altText
      }
      oralHistories {
        title
        _rawBody
      }
    }
    allSanityEpisode(sort: { fields: releaseDate, order: ASC }) {
      episodes: edges {
        episode: node {
          id: _id
          title
          slug {
            current
          }
          coverImage {
            asset {
              id
              originalFilename
              url
            }
            altText
          }
        }
      }
    }
  }
`;

export default function FijiBaat({
  data: {
    sanityFijiBaatPage: { pageTitle, coverImage, oralHistories },
    allSanityEpisode: { episodes }
  }
}) {
  return (
    <Layout title="Fiji Baat" page="fiji-baat" url="/fiji-baat">
      <h1 className="font-h1">{pageTitle}</h1>
      <Image image={coverImage} className="cover-image" />
      <h2 className="font-h2">{oralHistories.title}</h2>
      <PortableText blocks={oralHistories._rawBody} />
      {episodes.map(({ episode }) => {
        return (
          <Link
            key={episode.id}
            to={`/fiji-baat/episodes/${episode.slug.current}`}
            className="episode-card"
          >
            {episode.title && <h2 className="font-h2">{episode.title}</h2>}
            {episode.coverImage ? (
              <Image
                image={episode.coverImage}
                className="episode-card___image"
              />
            ) : (
              <div className="episode-card___image empty"></div>
            )}
          </Link>
        );
      })}
    </Layout>
  );
}
